// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import { isLocal } from './configUtils';
import { ErrorCode } from './errorUtils';

type CustomStatusHandler = { status: number; errorHandler: (errText: string) => ErrorCode }[];

/**
 * Checks the response from a fetch request and throws an error if the response is not OK.
 * @param res The response object from the fetch request.
 * @param customStatusMessages An map of status codes and error codes to throw if the response status matches.
 */
const checkResponse = async (res: Response, customStatusMessages?: CustomStatusHandler) => {
  if (!res.ok) {
    if (customStatusMessages) {
      const { error } = await res.json();

      customStatusMessages.forEach(({ status, errorHandler }) => {
        if (res.status === status) {
          throw new Error(errorHandler(error));
        }
      });
    }

    switch (res.status) {
      case 401:
        throw new Error(ErrorCode.SessionExpired);
      case 429:
        throw new Error(ErrorCode.TooManyRequests);
      default:
        throw new Error(ErrorCode.Unknown);
    }
  }

  const data = await res.json();

  return data;
};

export const fetchGet = async (
  url: string,
  data: { headers: HeadersInit },
  customStatusMessages?: CustomStatusHandler
) => {
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...data.headers,
    },
    redirect: 'follow',
    referrer: 'no-referrer',
  }).then((res) => checkResponse(res, customStatusMessages));
};

export const fetchPost = async <T>(
  url: string,
  data: { body: BodyInit; headers: HeadersInit },
  customStatusMessages?: CustomStatusHandler
) => {
  // Since we are using different ports in the local environment, we need to set the credentials to 'include'
  // to allow cookies and other credentials to be sent with cross-origin requests. In other environments, use 'same-origin'.
  const credentials = isLocal() ? 'include' : 'same-origin';

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials,
    headers: {
      'Content-Type': 'application/json',
      ...data.headers,
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: data.body,
  }).then((res) => checkResponse(res, customStatusMessages) as T);
};

/**
 * Extracts the domain name from a URL, removing any paths, query parameters, or fragments.
 * @param url The full URL from which the domain name should be extracted.
 * @returns The domain name.
 */
export const extractDomain = (url: string): string => {
  try {
    const urlObj = new URL(url);
    return urlObj.origin;
  } catch (error) {
    console.error('Invalid URL provided:', error);
    return url;
  }
};
