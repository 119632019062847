// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import {
  createContext,
  useContext,
  useState,
  ChangeEventHandler,
  FormEventHandler,
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';
import { Box, Dialog, Select, MenuItem, SelectChangeEvent, ThemeProvider, CssBaseline } from '@mui/material';
import UsernamePage from './LoginFormPages/UsernamePage';
import PasswordPage from './LoginFormPages/PasswordPage';
import LoadingPage from '../Common/LoadingPage';
import { changeLanguage, t, Language } from '../../i18n/i18n';
import { ErrorCode, getErrorCode } from '../../utils/errorUtils';
import {
  DEFAULT_LANGUAGE,
  getLanguagesInOwnLanguage,
  LanguageSupported,
  SUPPORTED_LANGUAGES,
} from '../../i18n/languages';
import {
  redirectBackToProduct,
  redirectToEnterpriseLogin,
  loginWithCognito,
  checkAccount,
  validateUserWithMFA,
  getLoginToken,
} from '../../utils/authServiceUtils';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { loginTheme } from '../Themes/Themes';
import Logo from '../Logo';
import MFAPage from './LoginFormPages/MFAPage';
import PromptPage from './LoginFormPages/PromptPage';

export const enum Page {
  Username,
  Password,
  MFA,
  Prompt,
  Migrated,
}

interface ILoginState {
  username: string;
  password: string;
  pin: string;
  errorCode: ErrorCode | null;
  isLoading: boolean;
}

interface LoginFormContext {
  loginState: ILoginState;
  goBack: () => void;
  onUsernameChange: ChangeEventHandler;
  onPasswordChange: ChangeEventHandler;
  onCodeChange: ChangeEventHandler;
  onContinue: FormEventHandler;
  onSubmit: FormEventHandler;
  onCodeSubmit: FormEventHandler;
  onNavigateToConfigureMFA: FormEventHandler;
  backToProduct: FormEventHandler;
}

const loginFormContext = createContext<LoginFormContext | null>(null);

export const useLoginFormContext = () => {
  const ctx = useContext(loginFormContext);

  if (ctx === null) {
    throw new Error("Could not access the Login Form's context.");
  }

  return ctx;
};

export const LoginForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const csrfToken = searchParams.get('csrfToken');
  const redirectTo = searchParams.get('redirectTo');
  const language = searchParams.get('lang') as Language;

  // The session returned after logging in with Cognito
  const session: MutableRefObject<string | undefined> = useRef();

  const [page, setPage] = useState(Page.Username);
  const [lang, setLang] = useState(language ?? DEFAULT_LANGUAGE);

  const initialState = {
    username: '',
    password: '',
    pin: '',
    errorCode: null,
    isLoading: false,
  };
  const [loginState, setLoginState] = useState<ILoginState>(initialState);
  const navigate = useNavigate();

  const languageList = SUPPORTED_LANGUAGES;

  const validateUsername = (): boolean => {
    if (loginState.username === '') {
      setLoginState({
        ...loginState,
        isLoading: false,
        errorCode: ErrorCode.UsernameRequired,
      });
      return false;
    }

    return true;
  };

  const validatePassword = (): boolean => {
    if (loginState.password === '') {
      setLoginState({
        ...loginState,
        isLoading: false,
        errorCode: ErrorCode.PasswordRequired,
      });
      return false;
    }

    return true;
  };

  const setErrorState = (err: any) => {
    console.error(err);
    setLoginState({
      ...loginState,
      isLoading: false,
      errorCode: getErrorCode(err),
    });
  };

  const onContinue = async (event: FormEvent) => {
    event.preventDefault();
    setLoginState({
      ...loginState,
      isLoading: true,
      errorCode: null,
    });

    try {
      if (validateUsername()) {
        const { username } = loginState;
        if (!csrfToken || !redirectTo) {
          return navigate({ pathname: `/error/${ErrorCode.Unknown}`, search: searchParams.toString() });
        }
        // Check if it is an enterprise email
        const account = await checkAccount(username);

        // if enterprise user, redirect to the login endpoint with csrf token and redirectUrl passed in the params
        if (account.isEnterprise) {
          await redirectToEnterpriseLogin({ email: username, csrfToken, redirectTo });
        } else if (account.isMigratedUser) {
          // if it's a migrated user go to the migrated users page
          setLoginState({
            ...loginState,
            isLoading: false,
            errorCode: null,
          });
          return navigate({ pathname: '/migrated-account', search: searchParams.toString() }, { state: account.email });
        } else {
          // if false, go back to local login
          setLoginState({
            ...loginState,
            isLoading: false,
            errorCode: null,
          });
          setPage(Page.Password);
        }
      }
    } catch (err) {
      // log an error in case the call to the auth service failed due to internet connectivity. This will prompt the user to try again.
      setErrorState(err);
    }
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setLoginState({
      ...loginState,
      isLoading: true,
      errorCode: null,
    });

    if (validatePassword()) {
      try {
        if (!csrfToken || !redirectTo) {
          return navigate({ pathname: `/error/${ErrorCode.Unknown}`, search: searchParams.toString() });
        }
        // Validate the user credential with Cognito
        const response = await loginWithCognito(loginState.username, loginState.password, csrfToken, redirectTo);
        if (response.challengeName === 'SOFTWARE_TOKEN_MFA') {
          session.current = response.session;
          setLoginState({
            ...loginState,
            isLoading: false,
            errorCode: null,
          });
          setPage(Page.MFA);
        } else {
          // If a 200 status code is received without a challenge name, the user is authenticated
          // with only a username and password. Redirect the user to the MFA prompt page in this case.
          setPage(Page.Prompt);
          setLoginState({
            ...loginState,
            isLoading: false,
            errorCode: null,
          });
        }
      } catch (err) {
        // If the validation with Cognito failed
        setErrorState(err);
      }
    }
  };

  const onNavigateToConfigureMFA = () => {
    setLoginState({
      ...loginState,
      isLoading: true,
    });
    // Navigate to the MFA setup page
    return navigate(
      { pathname: '/mfa/setup', search: searchParams.toString() },
      {
        state: { redirectFromLogin: true },
      }
    );
  };

  const backToProduct = async () => {
    setLoginState({
      ...loginState,
      isLoading: true,
    });

    try {
      const token = await getLoginToken(csrfToken!);
      redirectBackToProduct(redirectTo!, { token });
    } catch (err) {
      return navigate({ pathname: `/error/${getErrorCode(err)}`, search: searchParams.toString() });
    }
  };

  const onCodeSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setLoginState({
      ...loginState,
      isLoading: true,
      errorCode: null,
    });

    if (!session.current) {
      // If the session is missing, we will go back to the username page
      setLoginState(initialState);
      setPage(Page.Username);
      return;
    }

    if (!loginState.pin) {
      setLoginState({
        ...loginState,
        isLoading: false,
        errorCode: ErrorCode.MFACodeRequired,
      });
      return;
    }

    try {
      if (!csrfToken || !redirectTo) {
        return navigate({ pathname: `/error/${ErrorCode.Unknown}`, search: searchParams.toString() });
      }
      // Validate the user credential with MFA
      const response = await validateUserWithMFA(loginState.pin, session.current, loginState.username, csrfToken);

      if (response.token) {
        // If the credential is valid, we will pass the JWT returned from cognito
        redirectBackToProduct(redirectTo, { token: response.token });
        return;
      } else {
        throw Error(ErrorCode.Unknown);
      }
    } catch (err) {
      if (getErrorCode(err) === ErrorCode.SessionExpired) {
        // If the session has expired, we will go back to the username page
        setLoginState(initialState);
        setPage(Page.Username);
        setErrorState(err);
        return;
      }

      // If the validation with Cognito failed
      setErrorState(err);
    }
  };

  const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      username: event.target.value,
    });
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      password: event.target.value.trim(),
    });
  };

  const onCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      pin: event.target.value.trim(),
    });
  };

  const onLanguageChange = (event: SelectChangeEvent<string>) => {
    try {
      const newLang = event.target.value as Language;
      changeLanguage(newLang);
      setLang(newLang);

      // Also update the search param to reflect on the new language change
      searchParams.set('lang', newLang);
      setSearchParams(searchParams);
    } catch (err) {
      setErrorState(err);
    }
  };

  const goBack = () => {
    setPage(Page.Username);
    setLoginState({
      ...loginState,
      isLoading: false,
      password: '',
      errorCode: null,
    });
  };

  const loadPage = () => {
    switch (page) {
      case Page.Username:
        return <UsernamePage />;
      case Page.Password:
        return <PasswordPage />;
      case Page.MFA:
        return <MFAPage />;
      case Page.Prompt:
        return <PromptPage />;
      default:
        // Default to the username page
        return <UsernamePage />;
    }
  };

  useEffect(() => {
    // Redirect to the product if the csrfToken is missing
    if (!csrfToken && redirectTo) {
      setLoginState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      window.location.href = redirectTo;
    }
  }, [csrfToken, redirectTo]);

  return (
    <ThemeProvider theme={loginTheme}>
      <CssBaseline />
      <Dialog open>
        {loginState.isLoading ? (
          <LoadingPage />
        ) : (
          <Box>
            <Logo />
            <loginFormContext.Provider
              value={{
                loginState,
                goBack,
                onUsernameChange,
                onPasswordChange,
                onCodeChange,
                onContinue,
                onSubmit,
                onCodeSubmit,
                onNavigateToConfigureMFA,
                backToProduct,
              }}>
              <Box>{loadPage()}</Box>
            </loginFormContext.Provider>
            {/* Language selector fixed at the bottom and centered */}
            <Box
              sx={{
                position: 'absolute',
                bottom: 16, // Position the box 16px from the bottom
                left: '50%', // Center horizontally
                transform: 'translateX(-50%)',
                textAlign: 'center',
              }}>
              <Select
                onChange={onLanguageChange}
                defaultValue={lang}
                value={lang}
                labelId="language-select-label"
                id="language-select"
                label={t({
                  defaultMessage: 'Language',
                  id: 'y1Z3or',
                })}>
                {languageList.map((langCode) => (
                  <MenuItem key={langCode} value={langCode}>
                    {getLanguagesInOwnLanguage(langCode as keyof typeof LanguageSupported)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        )}
      </Dialog>
    </ThemeProvider>
  );
};
