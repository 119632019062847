// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import {
  Alert,
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Logo from '../Logo';
import { profileTheme } from '../Themes/Themes';
import LoadingPage from '../Common/LoadingPage';
import { t } from '../../i18n/i18n';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { checkMFAStatus } from '../../utils/authServiceUtils';
import { ErrorCode, getErrorCode } from '../../utils/errorUtils';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';

interface IProfileState {
  isLoading: boolean;
  isMFAEnabled: boolean;
}

export const Profile = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');
  const productName = searchParams.get('productName');

  const [state, setState] = useState<IProfileState>({
    isLoading: true,
    isMFAEnabled: false,
  });

  const checkMFA = async () => {
    // Check if MFA is enabled for the user
    try {
      const isMFAEnabled = await checkMFAStatus();
      setState({ ...state, isLoading: false, isMFAEnabled });
    } catch (err) {
      // Return to the login page if the session has expired
      const errorCode = getErrorCode(err);
      if (errorCode === ErrorCode.SessionExpired) {
        return navigate({ pathname: '/login', search: searchParams.toString() });
      }

      return navigate({ pathname: `/error/${errorCode}`, search: searchParams.toString() });
    }
  };

  const handleReturn = () => {
    if (!redirectTo) {
      return navigate({ pathname: `/error/${ErrorCode.Unknown}`, search: searchParams.toString() });
    }
    window.location.href = redirectTo;
  };

  useEffect(() => {
    if (!redirectTo || !productName) {
      return navigate({ pathname: `/error/${ErrorCode.Unknown}`, search: searchParams.toString() });
    }

    checkMFA();
  }, []); // eslint-disable-line

  return (
    <ThemeProvider theme={profileTheme}>
      <CssBaseline />
      <Dialog open>
        {state.isLoading ? (
          <LoadingPage />
        ) : (
          <Box>
            <Logo />
            <DialogTitle id="form-dialog-title">{t({ defaultMessage: 'Profile', id: 'itPgxd' })}</DialogTitle>
            <DialogContent>
              <Grid container>
                {/* Change Password Section */}
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {t({
                      defaultMessage: 'Manage Password',
                      id: 'dyTjIX',
                    })}
                  </Typography>
                  <Link to={{ pathname: `/change-password`, search: searchParams.toString() }}>
                    <Button type="button" variant="contained">
                      {t({
                        defaultMessage: 'Change my password',
                        id: 'pZwWyu',
                      })}
                    </Button>
                  </Link>
                </Grid>

                {/* Setup MFA Section */}
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {t({
                      defaultMessage: '2-Step Authentication',
                      id: 'vsNhRD',
                    })}
                  </Typography>
                  {!state.isMFAEnabled ? (
                    <Link to={{ pathname: `/mfa/setup`, search: searchParams.toString() }}>
                      <Button type="button" variant="contained">
                        {t({
                          defaultMessage: 'Register 2-Step Authentication',
                          id: 'x+ZsOB',
                        })}
                      </Button>
                    </Link>
                  ) : (
                    <Alert
                      severity="success"
                      iconMapping={{
                        success: <GppGoodOutlinedIcon fontSize="inherit" />,
                      }}>
                      {t({
                        defaultMessage: 'Your 2-Step Authentication is registered.',
                        id: 'BJ/MZU',
                      })}
                    </Alert>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button variant="contained" color="secondary" onClick={handleReturn}>
                    {t(
                      {
                        defaultMessage: 'Return to {productName}',
                        id: '5NP2vs',
                      },
                      { productName }
                    )}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        )}
      </Dialog>
    </ThemeProvider>
  );
};
